/* http://meyerweb.com/eric/tools/css/reset/
v2.0 | 20110126
License: none (public domain)
*/

.container-badge {
    position: absolute;
    right: 1em;
    top: .75em;
    background-color: #2EA4FF;
    color: white;
    padding: .4em;
    border-radius: 3px;
}

h1, h2, h3, h4, h5, h6 {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
}

body {
    margin: 0;
    margin: 0;
    background: #2EA4FF;
    color: #333;
    font-family: 'Roboto', sans-serif;
}

body.print {
    background: transparent;
}

input {
    border-radius: 0px !important;
}

.nav-link,
.nav-link:hover,
.nav-link:focus,
.nav-link:visited {
    text-decoration: none;
    color: inherit;
}

input:focus {
    outline: none;
}

.metronomeSlider{
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 20px;
    background: #2EA4FF;
    -webkit-transition: .2s;
    transition: opacity .2s;
    border-radius: 24px !important;
    cursor: pointer;
}

main {
    padding: 5em 1em;
}

.container {
    max-width: 800px;
    margin: 0 auto;
}
.is-custom-strong {
    font-size: 120%;
    color: #2EA4FF
}

@media only screen and (min-width: 960px) {
    main {
        padding: 2em 1em 2em 316px;
    }
}

.is-flex {
    display: flex;
}

.is-flex-justify {
    min-height: 600px;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.MuiCardHeader-title-242 {
    font-size: 18px !important;
}
.MuiCardContent-root-215 {
    min-height: 150px;
}

.u-align-right {
    text-align: right;
}

small {
    color: #555 !important;
}

.is-hidden {
    display: none;
}

.u-mt {
    margin-top: 1em !important;
}

.u-mb {
    margin-bottom: 1em !important;
}

.u-nm {
    margin-top: 0px !important;
    margin-block-start: 0px !important;
}

textarea,
input {
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    border-width: 0 0 1px 0;
    border-color: #666;
    font-size: 14px;
    padding: 10px 0;
    width: 100%;
    margin-bottom: 1em;
}

textarea {
    height: 100px;
}

textarea:focus,
input:focus {
    border-width: 0 0 1px 0;
    border-color: #3E67FF;
    outline: none;
}

.customPaper {
    position: relative;
    padding: 1em !important;
    margin-bottom: 1em;
}

.customPaper h1,
.customPaper h2,
.customPaper h3,
.customPaper h4,
.customPaper h5,
.customPaper h6,
.customPaper p {
    overflow: hidden;
    text-overflow: ellipsis;
}

.customDivider  {
    margin: 1em 0;
}

.alignRight {
    text-align: right;
}

.smallText {
    font-size: 12px !important;
    font-style: italic;
    color: grey !important;
}

.is-flex {
    height: 99vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media print {

    .logo {
        float: right;
        height: 50px;
    }

    .MuiGrid-item-38 {
        width: 50%;
    }

    .print .customPaper {
        padding: 0 !important;
    }

    .print .customPaper,
    .print .MuiPaper-elevation1-137 {
        box-shadow: 0px 1px 3px 0px rgba(255,255,255,1),
        0px 1px 1px 0px rgba(255,255,255,1),
        0px 2px 1px -1px rgba(255,255,255,1);
    }

    @page {
        size: auto;   /* auto is the initial value */
        margin: 0;  /* this affects the margin in the printer settings */
    }
    body  {
        margin: 0 1.6cm;
    }

    .customPaper,
    .MuiPaper-elevation1-137,
    .MuiPaper-elevation1-56 {
        box-shadow: none !important;
        box-shadow: 0px 0px 0px 0px rgba(225,225,225,1),0px 0px 0px 0px  rgba(225,225,225,1),0px 0px 0px 0px rgba(225,225,225,1) !important;
    }

    strong {
        font-family: Arial, Helvetica, sans-serif !important;
        font-weight: 700 !important;
    }

    .page-break {
        page-break-after: always;
    }

    .MuiTypography-h4-16 {
        margin-top: 50px !important;
    }

    .logo,
    .printHeader {
        padding-top: 1.5cm;
    }

    .nm-print {
        margin-top: 0px !important;
    }

    .container-badge {
        position: static;
        color: black;
        border: 1px solid black;
        float: right;
        margin-top: 1.5cm;
    }

    .ff-header {
        padding-top: 1.5cm;
    }
}

ol,
ul {
    text-align: left;
}

.is-error-snackbar {
    background-color: #d32f2f !important;
}

.is-success-snackbar {
    background-color: green !important;
}
.is-warning-snackbar {
    background-color: darkorange !important;
}

label p {
    padding-top: .65em !important;
}

.loader {
    text-align: center;
}

tr {
    height: auto !important;
}

.btn--primary {
    background-color: #3E67FF !important;
    color: white !important;
    margin: 1em 3px 1em 0 !important;
    min-height: 40px;
}

.btn--primary:disabled,
.btn--primary[disabled]{
  opacity: .5;
}

/* Helpers */
.mt-1 {
    margin-top: 1em !important;
}

.mt-1-5 {
    margin-top: 1.5em !important;
}

.mt-2 {
    margin-top: 2em !important;
}

.center-snackbar div {
    width: 100%;
    text-align: center;
}

.has-pointer:hover {
    cursor: pointer
}

.email-share {
    align-Items: center;
    display: flex;
    text-align: center;
    background: rgba(0, 0, 0, 0.08);
    border-radius: 24px;
    margin-right: 0.2em;
    margin-bottom: 2px;
    cursor: pointer;
}

.email-share:hover {


    background: rgba(0, 0, 0, 0.1);
    opacity:1.7;
}
.email-share span{
    /* email */
    padding: 0 0.5em 0 1em;
    word-break: keep-all;
    font-size: 12px
}

.email-share button{
    /* delete button */
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: grid;
    align-items: center;
    border: none;
    background: rgba(9, 30, 66, 0.08);
    cursor: pointer;
}

.email-share button:hover {
    background: rgba(9, 30, 66, 0.3);
}

@media only screen and (max-width: 960px) {
    .hide-on-mobile {
        display: none !important;
    }
}

@media only screen and (min-width: 960px) {
    .hide-on-desktop {
        display: none !important;
    }
}

.react-datetime-picker--closed .react-datetime-picker__wrapper {
    border-width: 0 0 1px 0;
    border-color: #666;
}

.react-datetime-picker--open .react-datetime-picker__wrapper {
    border-width: 0 0 1px 0;
    border-color: #3E67FF;
}

.react-datetime-picker__inputGroup__amPm {
    display: none !important;
}

.searchInput {
    margin-top: -3px !important;
}

.searchInput input {
    padding: 10px !important;
}

#firebaseui_container {
    margin-top: 2em;
}
