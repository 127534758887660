.main-container > * {
    margin-top: 15px;
}

.header-container {
    padding: 15px;
}

.container-subtitle {
    color: #666666;
}

.header-title {
    text-align: center;
    color: #cacaca;
    font-size: 2.3em;
}

.header-score {
    text-align: center;
    color: #cacaca;
    font-size: 5em;
    font-weight: 500;
}

.details-description {
    color: #666666;
}

ul.details-description {
    list-style-position: inside;
    padding-left: 0;
}



/* Range Component */

.range-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
    min-height: 36px;
    /*padding: 0 5%;*/
}

.range-sub-container {
    min-width: 200px;
    display: flex;
    flex-grow: 1;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    height: 36px;
}

.range-title {
    font-size: large;
    text-align: right;
    color: #666666;
    letter-spacing: 3px;
    min-width: 5.5em;
    max-width: 5.5em;
}

.print-report .range-title {
    color: initial;
}

.range-container svg {
    font-size: 2em;
    height: 20px;
    min-width: 20px;
    max-width: 20px;
    color: #3165fe;
    margin-left: 15px;
    margin-right: 15px;
}

.range-label-left {
    min-width: 6em;
    max-width: 6em;
    text-align: right;
    margin-right: 8px;
}

.range-label-right {
    min-width: 4em;
    max-width: 4em;
    text-align: left;
    margin-left: 8px;
}

.range-slider {
    -webkit-appearance: none;
    width: 100%;
    height: 8px;
    border-radius: 5px;
    outline: none;
    opacity: 1;
    background: #2eacff;
    background: linear-gradient(
            135deg,
            #2eacff 0%,
            #3165fe 100%);
}

.print-report .range-slider {
    border: thin solid #3165fe;
}

.range-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 25px;
    width: 25px;
    border-radius: 80% 0 60% 55% / 60% 0 80% 55%;
    transform: rotate(135deg);
    margin-top: -28px;
    background: #fefaff;
    border: 1px solid #bbb;
    -webkit-box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.2);
}

.details-container > h3 {
    margin-bottom: 0.3em;
}

.details-container > p, .details-container > ul {
    margin-top: 0.3em;
}

.container-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.print-report .container-header {
    padding-top: 0.3em;
    padding-bottom: 0.2em;
    margin-top: 2em;
    border-top: medium solid #3165fe;
    border-bottom: medium solid #3165fe;
}

.container-title > * {
    margin-top: 0;
    margin-bottom: 0;
}
