.timeline {
    background-color: var(--background-color);
    position: relative;
    overflow-y: hidden;
    max-width: calc(800px + 2*var(--default-padding));
    height: 420px;
    cursor: text;
    border-radius: 8px;
    -webkit-box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.33);
    -moz-box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.33);
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.33);
    /*Add to try to make it work again*/
    font-size: 10pt;
}
/*Add to try to make it work again*/
.timeline * {
    font-size: 10pt;
}
/*Add to try to make it work again*/
.scales * {
    font-size: 12px;
}
/*Add to try to make it work again*/
.scroll {
    overflow-y: hidden;
    width: 100%;
    height: 100%;
}
.timeline .scales {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 70px;
    background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    pointer-events: none;
    font-size: 12px;
}
.timeline .scales .tick {
    stroke: black;
}
.timeline .scales .bar {
    stroke: black;
}
.timeline .scales .domain {
	fill: none;
	stroke: #333;
	stroke-width: 1px;
}
.timeline .playhead {
	stroke: red;
	stroke-width: 1;
}
.timeline .playhead text {
    stroke: none;
    fill: red;
    font-size: 12px;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.timeline .pitch-trace {
	fill: #555;
}
.timeline .waveform .rule {
    stroke: #EAEAEA;
    stroke-width: 1px;
}
.timeline .waveform .peak {
	fill: #999;
}
.timeline .waveform .rms {
	fill: #777;
}
.timeline .timed-text line {
    stroke: #EEE;
    stroke-width: 1px;
    stroke-dasharray: 5, 5;
    user-select: none;
}
