.timeline {
    background-color: var(--background-color);
    position: relative;
    overflow-y: hidden;
    max-width: calc(800px + 2*var(--default-padding));
    height: 420px;
    cursor: text;
    border-radius: 8px;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.33);
    /*Add to try to make it work again*/
    font-size: 10pt;
}
/*Add to try to make it work again*/
.timeline * {
    font-size: 10pt;
}
/*Add to try to make it work again*/
.scales * {
    font-size: 12px;
}
/*Add to try to make it work again*/
.scroll {
    overflow-y: hidden;
    width: 100%;
    height: 100%;
}
.timeline .scales {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 70px;
    background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    pointer-events: none;
    font-size: 12px;
}
.timeline .scales .tick {
    stroke: black;
}
.timeline .scales .bar {
    stroke: black;
}
.timeline .scales .domain {
	fill: none;
	stroke: #333;
	stroke-width: 1px;
}
.timeline .playhead {
	stroke: red;
	stroke-width: 1;
}
.timeline .playhead text {
    stroke: none;
    fill: red;
    font-size: 12px;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.timeline .pitch-trace {
	fill: #555;
}
.timeline .waveform .rule {
    stroke: #EAEAEA;
    stroke-width: 1px;
}
.timeline .waveform .peak {
	fill: #999;
}
.timeline .waveform .rms {
	fill: #777;
}
.timeline .timed-text line {
    stroke: #EEE;
    stroke-width: 1px;
    stroke-dasharray: 5, 5;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.main-container > * {
    margin-top: 15px;
}

.header-container {
    padding: 15px;
}

.container-subtitle {
    color: #666666;
}

.header-title {
    text-align: center;
    color: #cacaca;
    font-size: 2.3em;
}

.header-score {
    text-align: center;
    color: #cacaca;
    font-size: 5em;
    font-weight: 500;
}

.details-description {
    color: #666666;
}

ul.details-description {
    list-style-position: inside;
    padding-left: 0;
}



/* Range Component */

.range-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
    min-height: 36px;
    /*padding: 0 5%;*/
}

.range-sub-container {
    min-width: 200px;
    display: flex;
    flex-grow: 1;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    height: 36px;
}

.range-title {
    font-size: large;
    text-align: right;
    color: #666666;
    letter-spacing: 3px;
    min-width: 5.5em;
    max-width: 5.5em;
}

.print-report .range-title {
    color: #000;
    color: initial;
}

.range-container svg {
    font-size: 2em;
    height: 20px;
    min-width: 20px;
    max-width: 20px;
    color: #3165fe;
    margin-left: 15px;
    margin-right: 15px;
}

.range-label-left {
    min-width: 6em;
    max-width: 6em;
    text-align: right;
    margin-right: 8px;
}

.range-label-right {
    min-width: 4em;
    max-width: 4em;
    text-align: left;
    margin-left: 8px;
}

.range-slider {
    -webkit-appearance: none;
    width: 100%;
    height: 8px;
    border-radius: 5px;
    outline: none;
    opacity: 1;
    background: #2eacff;
    background: linear-gradient(
            135deg,
            #2eacff 0%,
            #3165fe 100%);
}

.print-report .range-slider {
    border: thin solid #3165fe;
}

.range-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 25px;
    width: 25px;
    border-radius: 80% 0 60% 55% / 60% 0 80% 55%;
    transform: rotate(135deg);
    margin-top: -28px;
    background: #fefaff;
    border: 1px solid #bbb;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.2);
}

.details-container > h3 {
    margin-bottom: 0.3em;
}

.details-container > p, .details-container > ul {
    margin-top: 0.3em;
}

.container-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.print-report .container-header {
    padding-top: 0.3em;
    padding-bottom: 0.2em;
    margin-top: 2em;
    border-top: medium solid #3165fe;
    border-bottom: medium solid #3165fe;
}

.container-title > * {
    margin-top: 0;
    margin-bottom: 0;
}

/* http://meyerweb.com/eric/tools/css/reset/
v2.0 | 20110126
License: none (public domain)
*/

.container-badge {
    position: absolute;
    right: 1em;
    top: .75em;
    background-color: #2EA4FF;
    color: white;
    padding: .4em;
    border-radius: 3px;
}

h1, h2, h3, h4, h5, h6 {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
}

body {
    margin: 0;
    margin: 0;
    background: #2EA4FF;
    color: #333;
    font-family: 'Roboto', sans-serif;
}

body.print {
    background: transparent;
}

input {
    border-radius: 0px !important;
}

.nav-link,
.nav-link:hover,
.nav-link:focus,
.nav-link:visited {
    text-decoration: none;
    color: inherit;
}

input:focus {
    outline: none;
}

.metronomeSlider{
    -webkit-appearance: none;
    -moz-appearance: none;
         appearance: none;
    width: 100%;
    height: 20px;
    background: #2EA4FF;
    transition: opacity .2s;
    border-radius: 24px !important;
    cursor: pointer;
}

main {
    padding: 5em 1em;
}

.container {
    max-width: 800px;
    margin: 0 auto;
}
.is-custom-strong {
    font-size: 120%;
    color: #2EA4FF
}

@media only screen and (min-width: 960px) {
    main {
        padding: 2em 1em 2em 316px;
    }
}

.is-flex {
    display: flex;
}

.is-flex-justify {
    min-height: 600px;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.MuiCardHeader-title-242 {
    font-size: 18px !important;
}
.MuiCardContent-root-215 {
    min-height: 150px;
}

.u-align-right {
    text-align: right;
}

small {
    color: #555 !important;
}

.is-hidden {
    display: none;
}

.u-mt {
    margin-top: 1em !important;
}

.u-mb {
    margin-bottom: 1em !important;
}

.u-nm {
    margin-top: 0px !important;
    -webkit-margin-before: 0px !important;
            margin-block-start: 0px !important;
}

textarea,
input {
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    border-width: 0 0 1px 0;
    border-color: #666;
    font-size: 14px;
    padding: 10px 0;
    width: 100%;
    margin-bottom: 1em;
}

textarea {
    height: 100px;
}

textarea:focus,
input:focus {
    border-width: 0 0 1px 0;
    border-color: #3E67FF;
    outline: none;
}

.customPaper {
    position: relative;
    padding: 1em !important;
    margin-bottom: 1em;
}

.customPaper h1,
.customPaper h2,
.customPaper h3,
.customPaper h4,
.customPaper h5,
.customPaper h6,
.customPaper p {
    overflow: hidden;
    text-overflow: ellipsis;
}

.customDivider  {
    margin: 1em 0;
}

.alignRight {
    text-align: right;
}

.smallText {
    font-size: 12px !important;
    font-style: italic;
    color: grey !important;
}

.is-flex {
    height: 99vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media print {

    .logo {
        float: right;
        height: 50px;
    }

    .MuiGrid-item-38 {
        width: 50%;
    }

    .print .customPaper {
        padding: 0 !important;
    }

    .print .customPaper,
    .print .MuiPaper-elevation1-137 {
        box-shadow: 0px 1px 3px 0px rgba(255,255,255,1),
        0px 1px 1px 0px rgba(255,255,255,1),
        0px 2px 1px -1px rgba(255,255,255,1);
    }

    @page {
        size: auto;   /* auto is the initial value */
        margin: 0;  /* this affects the margin in the printer settings */
    }
    body  {
        margin: 0 1.6cm;
    }

    .customPaper,
    .MuiPaper-elevation1-137,
    .MuiPaper-elevation1-56 {
        box-shadow: none !important;
        box-shadow: 0px 0px 0px 0px rgba(225,225,225,1),0px 0px 0px 0px  rgba(225,225,225,1),0px 0px 0px 0px rgba(225,225,225,1) !important;
    }

    strong {
        font-family: Arial, Helvetica, sans-serif !important;
        font-weight: 700 !important;
    }

    .page-break {
        page-break-after: always;
    }

    .MuiTypography-h4-16 {
        margin-top: 50px !important;
    }

    .logo,
    .printHeader {
        padding-top: 1.5cm;
    }

    .nm-print {
        margin-top: 0px !important;
    }

    .container-badge {
        position: static;
        color: black;
        border: 1px solid black;
        float: right;
        margin-top: 1.5cm;
    }

    .ff-header {
        padding-top: 1.5cm;
    }
}

ol,
ul {
    text-align: left;
}

.is-error-snackbar {
    background-color: #d32f2f !important;
}

.is-success-snackbar {
    background-color: green !important;
}
.is-warning-snackbar {
    background-color: darkorange !important;
}

label p {
    padding-top: .65em !important;
}

.loader {
    text-align: center;
}

tr {
    height: auto !important;
}

.btn--primary {
    background-color: #3E67FF !important;
    color: white !important;
    margin: 1em 3px 1em 0 !important;
    min-height: 40px;
}

.btn--primary:disabled,
.btn--primary[disabled]{
  opacity: .5;
}

/* Helpers */
.mt-1 {
    margin-top: 1em !important;
}

.mt-1-5 {
    margin-top: 1.5em !important;
}

.mt-2 {
    margin-top: 2em !important;
}

.center-snackbar div {
    width: 100%;
    text-align: center;
}

.has-pointer:hover {
    cursor: pointer
}

.email-share {
    align-Items: center;
    display: flex;
    text-align: center;
    background: rgba(0, 0, 0, 0.08);
    border-radius: 24px;
    margin-right: 0.2em;
    margin-bottom: 2px;
    cursor: pointer;
}

.email-share:hover {


    background: rgba(0, 0, 0, 0.1);
    opacity:1.7;
}
.email-share span{
    /* email */
    padding: 0 0.5em 0 1em;
    word-break: keep-all;
    font-size: 12px
}

.email-share button{
    /* delete button */
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: grid;
    align-items: center;
    border: none;
    background: rgba(9, 30, 66, 0.08);
    cursor: pointer;
}

.email-share button:hover {
    background: rgba(9, 30, 66, 0.3);
}

@media only screen and (max-width: 960px) {
    .hide-on-mobile {
        display: none !important;
    }
}

@media only screen and (min-width: 960px) {
    .hide-on-desktop {
        display: none !important;
    }
}

.react-datetime-picker--closed .react-datetime-picker__wrapper {
    border-width: 0 0 1px 0;
    border-color: #666;
}

.react-datetime-picker--open .react-datetime-picker__wrapper {
    border-width: 0 0 1px 0;
    border-color: #3E67FF;
}

.react-datetime-picker__inputGroup__amPm {
    display: none !important;
}

.searchInput {
    margin-top: -3px !important;
}

.searchInput input {
    padding: 10px !important;
}

#firebaseui_container {
    margin-top: 2em;
}

